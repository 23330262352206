import React from 'react'
import styled from 'styled-components'
import imgLogo from '../../img/header/logo-bronce.svg'
import designTitle from '../../img/hero/design-title.svg'
import ameTitle from '../../img/hero/ame-title.svg'
import ubicacionTitle from '../../img/hero/ubicacion-title.svg'
import contactoMenu from '../../img/header/contacto-menu.svg'
import { device } from '../../utils/BreakPoints'

export default function Header({onSelectMenu}) {
    function SelectMenu(value){
        onSelectMenu(value)
    }

    return (
        <WrapperHeader>
            <LogoMonolith src={imgLogo} onClick={()=>{SelectMenu('home')}}/>
            <Navbar>
                <Ul>
                    <Li mgTop={0} src={designTitle} height={12} onClick={()=>{SelectMenu('desing')}}/>
                    <Li mgTop={`3px`} src={ameTitle} height={9} onClick={()=>{SelectMenu('amenidades')}}/>
                    <Li mgTop={0} src={ubicacionTitle} height={12} onClick={()=>{SelectMenu('ubicacion')}}/>
                </Ul>
            </Navbar>
            <Contactbar>
                <ContactUl>
                    <Li src={contactoMenu} height={9} onClick={()=>{SelectMenu('contacto')}}/>
                </ContactUl>
            </Contactbar>
        </WrapperHeader>
    )
}

/* Átomos */

const WrapperHeader = styled.div`
    width: 100%;
    background-color: rgba(5,27,28,0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    padding: .5rem;
    z-index: 12;
`
const LogoMonolith = styled.img`
    width: 10%;
    height: auto;
    margin: 8px 40px 4px 80px;
    @media ${device.laptop} {
        width: 28%;
        margin: 8px 0px 4px 20px;
    }
    @media ${device.mobileL} {
        width: 25%;
    }
`
const Navbar = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 30%;

    @media ${device.laptop} {
        display: none;
    }
`
const Contactbar = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 45%;
    @media ${device.laptop} {
        width: 65%;
    }

    @media ${device.tablet} {
        width: 60%;
    }
    @media ${device.mobileL} {
        width: 55%;
    }
`
const Ul = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`
const ContactUl = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`
const Li = styled.img`
    height: ${props => props.height};
    margin-top: ${props => props.mgTop};
    cursor: pointer;
    @media ${device.laptop} {
        height: 14px;
        margin-top: ;
    }
    @media ${device.tablet} {
        height: 10px;
        margin-top: ;
    }
`   
