import React from 'react'
import styled from 'styled-components'
import mieryPesado from '../../img/sections/footer/mier-y-pesado.svg'
import agatha from '../../img/sections/footer/avalon.svg'
import humboldt from '../../img/sections/footer/humboldt.svg'
import {device} from '../../utils/BreakPoints'

export default function Footer() {
    return (
        <WrapperFooter>
            <TitleFooter>
                Conoce otros  desarrollos de nuestra marca Agatha by QC 
            </TitleFooter>
            <ContainerLogos>
                <Logo src={mieryPesado} width={200}/>
                <Logo src={agatha} width={200}/>
                <Logo src={humboldt} width={220}/>
            </ContainerLogos>
        </WrapperFooter>
    )
}
const WrapperFooter = styled.div`
    height: auto;
    margin-bottom: 40px;
`
const ContainerLogos = styled.div`
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 80%;
    @media ${device.tablet} {
        flex-wrap: wrap;
        justify-content: center;
    }
`
const TitleFooter = styled.h2`
    font-weight: 100;
    font-size: 2em;
    line-height: 1.5em;
    text-align: center;
    color: #7a7a7a;
    font-family: 'Aeonik-Thin';
    @media ${device.tablet} {
        font-size: .8em;
    }
`
const Logo = styled.img`
    margin-top: 40px;
    margin-bottom: 40px;
    height: ${props => props.height};
    width: ${props => props.width};
`