import React,{ useEffect, useState } from 'react'
import styled from 'styled-components'
import contactTitle from '../../../img/sections/contacto-gold-title.svg'
import { getAsesor,sendMailAsesor } from '../../../services/ContactService';
import {device} from '../../../utils/BreakPoints'

export default function Contacto(props) {
    

    const[perfilAsesor,setPerfilAsesor] =useState({
        GUID: "",
        ID: "",
        Nombre_persona: "",
        Descripcion: "",
        Foto_colaborador: "",
        Correo: "",
        Telefono: "",
        Liga_Whatsapp: "",
        Anios: "",
        Suenios_cumplidos: "",
        Recomendacion1: "",
        Recomendacion2: "",
        Recomendacion3: "",
        Medalla1: "",
        Medalla2: "",
        Medalla3: "",
        Avalon: "",
        Monolith: ""
    });

    const[isSend,setIsSend] = useState(false);

    useEffect(()=>{
        InitAsesor();
    },[]);

    async function InitAsesor(){
        try {
            let data = await getAsesor().then(data => {
                return data;
            });

            setPerfilAsesor(data.data)

        } catch (e) {
            console.log(e);
        }
    }

    async function sendMail(data) {
        data.preventDefault();
        let duda = data.target[0].value;
        let mail = data.target[1].value;
        let dataMail = {
            "email_asesor": perfilAsesor.Correo,
            "name_asesor": perfilAsesor.Nombre_persona,
            "email_cliente": mail,
            "duda": duda
        }
        try {
            let data = await sendMailAsesor(dataMail).then(data => {
                return data;
            });
            setIsSend(data.estatus);
            
        } catch (e) {
            console.log(e);
        }
    }

    //console.log(perfilAsesor)
    return (
        <WrapperContacto ref={props.forwardRef}>
            <TitleSection src={contactTitle} height={32}/>
            <WrapperAsesor>
                <DatosContacto>
                    <h2>Contáctanos por medio de los siguientes teléfonos</h2>
                    <div>
                        <label>Teléfono directo Avalon</label>
                        <p>55 82182923</p>
                    </div>
                    <div>
                        <label>Teléfono directo Ventas</label>
                        <p>55 82182923</p>
                    </div>
                    <p>Agenda tu cita en cualquier momento</p>
                </DatosContacto>
                <ContactForm>
                    <h2>o uno de nuestros asesores te puede atender por medio de video llamada, WhatsApp o celular</h2>
                    <DatosAsesor>
                        <ImgAsesor src={perfilAsesor.Foto_colaborador} />
                        <InfoAsesor>
                            <div>
                                <div>
                                    <h3>{perfilAsesor.Nombre_persona}</h3>
                                    <p>{perfilAsesor.Descripcion}</p>
                                </div>
                            </div>
                        </InfoAsesor>
                        <NumberAsesor>
                            <div>
                                <div>
                                    <p>{perfilAsesor.Telefono}</p>
                                    <a href={perfilAsesor.Liga_Whatsapp} target={'_blank'} rel={"noreferrer"}>
                                        <p>Video llamada</p>
                                        <p>WhatsApp</p>
                                    </a>
                                </div>
                            </div>
                        </NumberAsesor>
                    </DatosAsesor>
                    <WrapperForm>
                        <p>Si desea información puede recibirla en su correo electrónico </p>
                        {(!isSend)&&
                        <form model="data"  onSubmit={(data) => sendMail(data)}>
                            <input  model="data.duda" type="text"  placeholder="" />
                            <input  model="data.mailUser" type="email" placeholder="" />
                            <button type="submit">Enviar</button>
                        </form>
                        }
                        {
                            (isSend)&&
                            <div>
                                <p>¡Muchas gracias por contactarme!</p>
                                <p>En breve estaré respondiendo tu correo. ¡Que tengas un excelente día!</p>
                            </div>
                        }
                    </WrapperForm>
                </ContactForm>
            </WrapperAsesor>
        </WrapperContacto>
    )
}
const WrapperContacto = styled.div`
    height: auto;
`
const TitleSection = styled.img`
    margin-top: 40px;
    margin-bottom: 40px;
    height: ${props => props.height};
    @media ${device.tablet} {
        margin-top: 0px;
        margin-bottom: 20px;
        height: 28px;
    }
    @media ${device.mobileL} {
        height: 20px;
    }
`
const WrapperAsesor = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #26272782;
    @media ${device.tablet} {
        flex-wrap: wrap;
    };
`
const DatosContacto = styled.div`
    color: #f2f2f2;
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 15px;
    box-sizing: border-box;
    font-family: 'Aeonik-Light';
    padding: 40px;
    @media ${device.tablet} {
        margin: 0 auto;
        width: 90%;
        padding: 10px 10px 0px 10px;
        flex-direction: column;
        text-align: center;
        border-bottom: 1px solid #ffffff;
    };
    h2{
        width: 100%;
        font-weight: 100;
        font-size: 1em;
        line-height: 1.5em;
        @media ${device.tablet} {
            font-size: .8em;
            margin-block-start: 0em;
            margin-block-end: 0em;
        };
    }
    >div{
        margin: 15px 0 0px 0;
        label{
            font-size: 1em;
            @media ${device.tablet} {
                font-size: .6em;
            };
        };
        p{
            font-size: 1.5em;
            line-height:2em;
            letter-spacing: .3em;
            margin-block-end: 1em;
            @media ${device.tablet} {
                font-size: 1.3em;
                letter-spacing: .4em;
                margin-block-start: 0em;
                margin-block-end: 0em;
            };
        }
    };
`
const ContactForm = styled.div`
    width: 75%;
    color: #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    position: relative;
    padding: 40px;
    @media ${device.tablet} {
        width: 100%;
        padding: 10px 10px 0px 10px;
    };
    h2{
        font-weight: 100;
        font-size: 1em;
        line-height: 1em;
        @media ${device.tablet} {
            margin: 0 auto;
            width:90%;
            font-size: 1em;
            text-align: center;
        };
    };
    ::after{
        padding: 0;
        margin: 0;
        display: block;
        content: "";
        width: 1px;
        height: 97%;
        background-color: #ffffff80;
        position: absolute;
        left: 0;
        top: 1.5%;
    }
    div{

    }
`
const DatosAsesor = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    box-sizing: border-box;
    height: 240px;
    @media ${device.tablet} {
        flex-wrap: wrap;
        justify-content: center;
        height: auto;
    };
    
`
const ImgAsesor = styled.img`
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 0px 1px 20px 0px #4d4d4d;
    border-radius: 100%;
    @media ${device.tablet} {
        max-width: 80px;
        max-height: 80px;
    };
`
const InfoAsesor =styled.div`
    width: 50%;
    padding-top: 40%;
    position: relative;
    @media ${device.tablet} {
        width: 100%;
    };
    >div{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        padding: 10px;
        box-sizing: border-box;
        
        div{
            width:100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            @media ${device.tablet} {
                justify-content: center;
            };
            h3{
                font-weight: 500;
                font-size: 1.6em;
                line-height: 1em;
                margin-block-start: 0em;
                margin-block-end: 0em;
                @media ${device.tablet} {
                    font-size: 1em;
                };
            };
            p{
                font-weight: 100;
                font-size: 1em;
                line-height: 1em;
                letter-spacing: .2em;
                font-style: italic;
                margin-block-start: 1em;
                margin-block-end: 1em;
                @media ${device.tablet} {
                    font-size: .8em;
                };
            };
        }
    }
`
const NumberAsesor = styled.div`
    width: 25%;
    padding-top: 30%;
    position: relative;
    @media ${device.tablet} {
        margin-top: 85px;
        width: 100%;
    };
    div{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        padding: 10px;
        box-sizing: border-box;
        div{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            padding-left: 10%;
            @media ${device.tablet} {
                padding-left: 0%; 
            };
            p{
                font-weight: 100;
                font-size: 1.5em;
                line-height: 1em; 
                margin-block-start: 0em;
                margin-block-end: 0.5em;
                @media ${device.tablet} {
                    font-size: 2em;
                    line-height: 1em;
                };
            }
            a{
                margin-top: 10px;
                width: 100%;
                color: #ffffff;
                text-decoration: none;
                p{
                    font-weight: 100;
                    font-size: 1em;
                    line-height: 1em; 
                }
            }
            :link {
                color: #ffffff;
            }
        }
    }
`
const WrapperForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-top: 40px;
    padding: 12px;
    @media ${device.tablet} {
        margin-top: 40px;
        margin-bottom: 0px;
        width: 99%;
    };
    form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        margin-top: 15px;
        input[type=text] {
            background-color: #d6d6d6;
            width: 90%;
            min-height: 60px;
            outline: none;
            border: none;
            margin: 10px 0;
        }
        input[type=email] {
            background-color: #d6d6d6;
            width: 45%;
            min-height: 25px;
            outline: none;
            border: none;
            margin: 10px 50% 10px 0;
        }
        button{
            width: 100%;
            max-width: 70px;
            border: solid 1px #ffffff;
            background-color: transparent;
            padding: 5px;
            box-sizing: border-box;
            cursor: pointer;
            color: #ffffff
        }
        div{
            margin: 20px;
            width: 100%
        }
    }
`