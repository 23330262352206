import React from 'react'
import styled from 'styled-components'
import designTitle from '../../../img/sections/gold-design-title.svg'
import SlideDesign from './SlideDesign'
import cardUno from '../../../img/sections/sliderdesign/card-01.jpg'
import cardDos from '../../../img/sections/sliderdesign/card-02.jpg'
import cardTres from '../../../img/sections/sliderdesign/card-03.jpg'
import imgInsp from '../../../img/sections/inspiracion/inspiracion.jpg'
import imgAvance from '../../../img/sections/diseño/avance.jpg'
import {device} from '../../../utils/BreakPoints'

export default function Diseño(props) {
    return (
        <WrapperDesign ref={props.forwardRef}>
            <TitleSection src={designTitle} height={40}/>
            <SlideDesign/>
            <ContainerInno>
                <Innovacion>
                    Innovación
                </Innovacion>
                <Parrafo>
                Monolith a integrado avances tecnológicos exclusivos en tus espacios para que puedas disfrutar de otra manera tu departamento, tu espacio, tu hogar.
                </Parrafo>
            </ContainerInno>
            <ContainerCards>
                <Card imagen={cardUno}>
                    <TextCard>
                        Sonido estereo en tu apartamento para ambientar tus espacios 
                    </TextCard>
                </Card>
                <Card imagen={cardDos}>
                    <TextCard>
                        Ilumina  tu departamento con tus dispositivos moviles 
                    </TextCard>
                </Card>
                <Card imagen={cardTres}>
                    <TextCard>
                    Modula la entrada de luz natural con tus persianas usando el control remoto 
                    </TextCard>
                </Card>
            </ContainerCards>
            <ContainerIns bgColor={'#9da1a6'} shadow={'right'}>
                <ImgInsp src={imgInsp}/>
                <CardInsp>
                    <TitleInsp>
                        Inspiración
                    </TitleInsp>
                    <ParrafoInsp>
                    Avalon inicialmente fue diseñado por Enrique Norten, y se fue adaptando a las necesidades actuales.

La inspiración fue el diseño escandinavo Los países nórdicos tienen como objetivo crear espacios confortables, luminosos y modernos. El estilo nórdico se ha consolidado como corriente líder en lo que respecta a la Arquitectura, pero también en el diseño, gracias a una estética que se define por su sencillez y funcionalidad y que persigue la satisfacción y bienestar.

Se define como la fusión entre tecnología y sabiduría, entre modernidad y tradición Asi nace avalon como una respuesta a un nuevo de estilo de vida un oasis en la ciudad.
                    </ParrafoInsp>
                </CardInsp>
            </ContainerIns>
            <ContainerVista>
                <TitleShowroom>
                     Vista 360 Showroom 
                </TitleShowroom>
                <WrapperIframe>
                    <div>
                        <iframe title={'matterport'} src={'https://my.matterport.com/show/?m=9RJxTcG36RM'} />
                    </div>
                </WrapperIframe>
            </ContainerVista>
            <ContainerIns bgColor={'#5c646b'} shadow={'left'}>
                <ImgAvanceMob src={imgAvance}/>
                <CardInsp>
                    <Porcentaje>
                        <h1>52%</h1>
                        <h2>AVANCE GENERAL</h2>
                    </Porcentaje>
                    <ContainerList>
                        <Avance>
                            <h3>
                                EXCAVACIÓN
                            </h3>
                            <h2>
                                100%
                            </h2>
                        </Avance>
                        <Avance>
                            <h3>
                                CIMENTACIÓN
                            </h3>
                            <h2>
                                100%
                            </h2>
                        </Avance>
                        <Avance>
                            <h3>
                                ESTRUCTURA
                            </h3>
                            <h2>
                                56%
                            </h2>
                        </Avance>
                        <Avance>
                            <h3>
                                ACABADOS
                            </h3>
                            <h2>
                                6%
                            </h2>
                        </Avance>
                        <Avance>
                            <h3>
                                LIMPIEZA
                            </h3>
                            <h2>
                                0%
                            </h2>
                        </Avance>
                        <Fecha>
                            <h3>
                                DESARROLLO TERMINADO
                            </h3>
                            <h2>
                                JUNIO 2022
                            </h2>
                        </Fecha>
                    </ContainerList>
                </CardInsp>
                <ImgAvance src={imgAvance}/>
            </ContainerIns>
        </WrapperDesign>
    )
}

const WrapperDesign = styled.div`
    height: auto;
    opacity: 1;
    transition: all 1s;
`
const TitleSection = styled.img`
    margin-top: 40px;
    margin-bottom: 40px;
    height: ${props => props.height};
    @media ${device.tablet} {
        margin-top: 0px;
        margin-bottom: 20px;
        height: 28px;
    }

`
const ContainerInno = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    @media ${device.tablet} {
        flex-wrap: wrap;
        margin-top: 0px;
    }
`
const Innovacion = styled.h2`
    width: 30%;
    font-weight: 400;
    font-size: 2em;
    font-family: 'Aeonik-Light';
    text-align: center;
    color: #7a7a7a;
    @media ${device.tablet} {
        margin-block-start: 0.83em;
        margin-block-end: 0.3em;
    }
    
`
const Parrafo = styled.p`
    width:70%
    font-weight: 300;
    font-size: 1.4em;
    color: #7a7a7a;
    font-family: 'Aeonik-Light';
    @media ${device.laptop} {
        width:65%;
        font-size: 1.2em;
    }
    @media ${device.tablet} {
        width:100%;
        font-size: 1em;
    }
`
const ContainerCards = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 120px;
    @media ${device.tablet} {
        justify-content: center;
        flex-wrap: wrap;
    }
`
const Card = styled.div`
    background-image: url(${props => props.imagen});
    width: 320px;
    height: 320px;
    background-position: top center; 
    background-repeat: no-repeat; 
    background-size: cover;
    padding: 40px;
    text-align: center;
    font-family: 'Aeonik-Light';
    @media ${device.laptop} {
        width: 160px;
        height: 160px;
    }
    @media ${device.tablet} {
        width: 320px;
        height: 320px;
        margin-bottom: 40px;
    }
    @media ${device.mobileL} {
        height: 220px;
    }
    @media ${device.mobileS} {
        height: 160px;
    }
`
const TextCard = styled.p`
    font-weight: 300;
    font-size: 1.5em;
    color: #ffffff;
    @media ${device.laptop} {
        font-size: 1em;
    }
    @media ${device.tablet} {
        font-size: 1.4em;
    }
    @media ${device.mobileS} {
        font-size: 1em;
    }
`
/*Diseño sección Inspiración */
const ContainerIns = styled.div`
    background-color: ${props => props.bgColor};
    display: flex;
    flex-wrap: nowrap;
    @media ${device.tablet} {
        flex-wrap: wrap;
    }
`
const ImgInsp = styled.img`
    width: 55%;
    box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    -webkit-box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    -moz-box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    @media ${device.tablet} {
        width:100%;
    };
`
const CardInsp = styled.div`
    width:50%;
    padding: 40px;
    @media ${device.tablet} {
        width:100%;
        padding: 20px;
    }
`
const TitleInsp = styled.h2`
    font-weight: 100;
    font-size: 2.5em;
    line-height: .8em;
    color: #ffffff;
    text-align: right;
    font-family: 'Aeonik-Light';
    margin-bottom: 40px;
    @media ${device.tablet} {
        font-size: 1em;
        line-height: .1em;
        color: #ffffff;
        text-align: left;
    }
`
const ParrafoInsp = styled.p`
    font-weight: 300;
    font-size: 1.2em;
    color: #ffffff;
    text-align: right;
    font-family: 'Aeonik-Light';
    @media ${device.laptop} {
        font-size: .8em;
        text-align: left;
    }
    @media ${device.tablet} {
        font-weight: 300;
        font-size: .6em;
        text-align: left;
    }
`
/*Diseño sección Vista 360 */
const ContainerVista = styled.div`
    display: flex;
    flex-direction: column;
`
const TitleShowroom = styled.h2`
    font-weight: 100;
    font-size: 2.5em;
    line-height: 2em;
    text-align: center;
    color: #7a7a7a;
    font-family: 'Aeonik-Thin';
    @media ${device.tablet} {
        font-size: 1.5em;
        line-height: 1em;
    }
`
const WrapperIframe = styled.div`
    width: 100%;
    padding-top: 56.25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 80px;
    div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        iframe{
            width: 100%;
            height: 100%;
            border: none;
            object-fit: contain;
        }
    }
`
/*Diseño sección Avance General */
const ImgAvance = styled.img`
    width: 55%;
    box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    -webkit-box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    -moz-box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    @media ${device.tablet} {
        display: none
    }

`
const ImgAvanceMob = styled.img`
    width: 100%;
    display:none;
    box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    -webkit-box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    -moz-box-shadow: -8px 0px 22px 1px rgba(0,0,0,0.28);
    @media ${device.tablet} {
        display: block;
    };
`
const Porcentaje = styled.div`
    display: flex;
    flex-wrap: nowrap;
    color: #FFFFFF;
    aling-items: center;
    margin-bottom: 40px;
    h1{
        font-weight: 500;
        font-size: 4em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        @media ${device.tablet} {
            font-size: 2.5em;
        };
    };
    h2{
        margin-left: 20px;
        width:50%;
        font-weight: 100;
        font-size: 1.2em;
        letter-spacing: .8em;
        margin-block-start: 0.8em;
        margin-block-end: 0em;
        font-family: 'Aeonik-Thin';
        @media ${device.tablet} {
            font-size: .8em;
        }
    }
`
const ContainerList = styled.div`
    border-left: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    padding: 0px 40px 0px 40px;
`
const Avance = styled.div`
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    aling-items: center;
    margin-bottom: 40px;
    @media ${device.tablet} {
        margin-bottom: 20px
    };
    h3{
        font-weight: 100;
        font-size: .8 em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        letter-spacing: .6em;
        font-family: 'Aeonik-Thin';
        @media ${device.tablet} {
            font-size: .7em;
        }
    };
    h2{
        font-weight: 500;
        font-size: 1.8em;
        line-height: 1.5em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        color: #f2f2f2;
        opacity: .5;
        font-family: 'Avenir-Medium';
        @media ${device.tablet} {
            font-size: 1em;
        }
    }
`
const Fecha = styled.div`
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    aling-items: center;
    h3{
        font-weight: 100;
        font-size: .6 em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        letter-spacing: .4em;
        opacity: .5;
        v
        @media ${device.tablet} {
            font-size: .6em;
        }
    }
    h2{
        font-weight: 500;
        font-size: 2em;
        line-height: 1.8em;
        letter-spacing: .4em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        color: #f2f2f2;
        font-family: 'Avenir-Medium';
        @media ${device.tablet} {
            font-size: 1.2em;
        }
    }
`