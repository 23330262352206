import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createGlobalStyle} from 'styled-components'
import AvenirLight from '../src/fonts/Avenir-Light.ttf'
import AvenirMedium from '../src/fonts/Avenir-Medium.ttf'
import AeonikLight from '../src/fonts/Aeonik-Light.ttf'
import AeonikThin from '../src/fonts/Aeonik-Thin.ttf'

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Avenir-Light';
    src: url(${AvenirLight}) format('truetype');
  }

  @font-face {
    font-family: 'Avenir-Medium';
    src: url(${AvenirMedium}) format('truetype');
  }
  
  @font-face {
    font-family: 'Aeonik-Light';
    src: url(${AeonikLight}) format('truetype');
  }

  @font-face {
    font-family: 'Aeonik-Thin';
    src: url(${AeonikThin}) format('truetype');
  }

  *{
    margin: 0;
    padding: 0;
  }

  html {
    margin: 0;
    padding: 0;
  }

  body{
    font-family: 'Avenir-Light';
    color: black;
  }
`


ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

