import React from 'react'
import styled from 'styled-components'
import ubicacionTitle from '../../../img/sections/ubicacion-title-god.svg'
import imgBanVista from '../../../img/sections/Ubicacion/banner-ub-1.jpg'
import imgBanPiso from '../../../img/sections/Ubicacion/banner-ub-2.jpg'

import {device} from '../../../utils/BreakPoints'
import WrapperMap from './WrapperMap'

export default function Ubicacion(props) {
    return (
        <WrapperUbicacion ref={props.forwardRef}>
            <TitleSection src={ubicacionTitle} height={40}/>
            <ContainerMap bgColor={'#968b62'}>
                <Mapa>
                    <div>
                        <WrapperMap/>
                    </div>
                </Mapa>
                <CardMap>
                    <h2>Desierto de los Leones, 5547</h2>
                    <p>Col. Alcantarilla, Del. Álvaro Obregón, C.P. 01729, Ciudad de México</p>
                    <h3>Una vida natural, en equilibrio y gran conectividad. Desierto es tu mejor opción en departamentos al sur de CDMX en la alcaldía Álvaro Obregón. Vive en un espacio seguro y disfruta de las mejores vistas de la Ciudad de México.</h3>
                    <h4>Desierto de los Leones, es la
                        puerta de entrada a una amplia
                        variedad de actividades culturales y
                        artísticas, espacios deportivos y
                        restaurantes típicos. Próximo a
                        importantes vías de acceso. Haciendo de este la fusión prefecta entre estética, función y ubicación.</h4>
                </CardMap>
            </ContainerMap>
            
            < BannerVista>
                <ImgBanVista src={imgBanVista}/>
            </BannerVista>
                <TitleVista>
                    Imagina tu nueva vista desde Monolith
                </TitleVista>
            < BannerVista>
                <ImgBanVista src={imgBanPiso}/>
                <div>
                    <p>IMAGEN TOMADA DEL NIVEL 32</p>
                </div>
            </BannerVista>
            
        </WrapperUbicacion>
    )
}
const WrapperUbicacion = styled.div`
    height: auto;
`
const TitleSection = styled.img`
    margin-top: 40px;
    margin-bottom: 40px;
    height: ${props => props.height};
    @media ${device.tablet} {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 28px;
    }
`
const BannerVista = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 40px;
    div{
        position: absolute;
        width: 100%;
        background-color: rgba(5,26,28,0.7);
        bottom: 1%;
        left: 0;
        z-index: 1;
        
        p{
            width: 98%;
            color: #ffffff;
            text-align: right;
            @media ${device.tablet} {
                font-size: .5em;
            }
        }
    }
`
const ImgBanVista = styled.img`
    width: 100%;
`
const TitleVista = styled.h2`
    font-weight: 100;
    font-size: 2.5em;
    line-height: 2em;
    text-align: center;
    color: #7a7a7a;
    font-family: 'Aeonik-Thin';
    @media ${device.tablet} {
        font-size: 1em;
    }
`
const ContainerMap = styled.div`
    background-color: ${props => props.bgColor};
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 80px;
    @media ${device.tablet} {
        flex-wrap: wrap;
    }
`
const CardMap = styled.div`
    width:30%;
    padding: 40px;
    color: #f2f2f2;
    font-family: 'Aeonik-Light';
    @media ${device.tablet} {
        width: 100%;
        padding: 20px;
    }
    h2{
        font-size: 1.5em;
        margin-bottom: 20px;
        @media ${device.tablet} {
            font-size: 1.2em;
        }
    }
    p{
        font-weight: 100;
        font-size: .9em;
        text-align: left;
        margin-bottom: 20px;
        @media ${device.tablet} {
            font-size: 1em;
        }
    }
    
    h3{
        font-size: 1.1em;
        font-weight: 100;
        margin-bottom: 20px;
        @media ${device.tablet} {
            font-size: .9em;
        }
    }
    h4{
        font-size: 1em;
        font-weight: 100;
        @media ${device.tablet} {
            font-size: .8em;
        }
    }
`
const Mapa = styled.div`
    width: 65%;
    padding-top: 35%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
    @media ${device.tablet} {
        width: 100%;
        padding-top: 80%;
    }
    >div{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`