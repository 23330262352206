import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home';
function App() {
  return (
      <Router>
        <React.Fragment>
          <Switch>
            <Route path="/" exact component={Home} />
          </Switch>
        </React.Fragment>
      </Router>
  );
}

export default App;