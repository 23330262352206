import * as Constantes from '../utils/Constantes';

export async function getAsesor(){

    const response = await fetch(Constantes.API_URL_QC + 'api/QC/asesorRand?SYS=Avalon',{
        method : 'get',
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    }).then(function(response){
        return response.json();
    }).then(function (data) {
        return data;
    });
    console.log(response);
    return response;
    
}

export async function sendMailAsesor(bodyMail){
    const response = await fetch(Constantes.API_URL_QC + 'api/QC/contacto-asesor',{
        method : 'post',
        body: JSON.stringify(bodyMail),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    }).then(function(response){
        return response.json();
    }).then(function (data) {
        return data;
    });

    return response;

}