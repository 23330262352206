import React, {  useRef, useState }  from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import styled from 'styled-components'
import Diseño from './components/Sections/Diseño'
import bgSection from './img/sections/gold-x-back.svg'
import Amenidades from './components/Sections/Amenidades'
import Ubicacion from './components/Sections/Ubicacion'
import Contacto from './components/Sections/Contacto'
import Footer from './components/Footer'
import {device} from './utils/BreakPoints'
import bgPreloader from '../src/img/preloader/bg-preloader.jpg'
import arroNext from '../src/img/preloader/arrow-pre.svg'
import logoInit from '../src/img/preloader/logo-inicio.svg'

function Home() {
    
    const [preloader, setPreloader] = useState(true)
    const refHome = useRef(null);
    const refDesing = useRef(null);
    const refAmenidades = useRef(null);
    const refUbicacion = useRef(null);
    const refContacto = useRef(null);

    function scrollTo(value){

        let scrollY = 0;
        let _home = refHome.current.getBoundingClientRect().top;
        let _desing = refDesing.current.getBoundingClientRect().top;
        let _amenidades = refAmenidades.current.getBoundingClientRect().top;
        let _ubicacion = refUbicacion.current.getBoundingClientRect().top;
        let _contacto = refContacto.current.getBoundingClientRect().top;


        let fix = window.pageYOffset;
        switch(value){
            case 'home':
                scrollY = _home;
            break;
            case 'desing':
                scrollY = _desing -40  + fix;
            break;
            case 'amenidades':
                scrollY = _amenidades -40 + fix;
            break;
            case 'ubicacion':
                scrollY = _ubicacion -20 + fix;
            break;
            case 'contacto':
                scrollY = _contacto -40 + fix;
            break;
            default:
                scrollY=0;
            break;
        }
        window.scrollTo(0,scrollY)

    }
  return (
    <div className="App">
    {preloader?
      <Preloader>
          <LogoPre src={logoInit}/>
          <ArrowNext src={arroNext} onClick={()=>{setPreloader(false)}}/>
      </Preloader>:
      <> 
      <Header onSelectMenu={(value)=> scrollTo(value)}/>
      <Hero onSelectMenu={(value)=> scrollTo(value)} forwardRef={refHome}/>
      <WrapperSections>
            <Diseño forwardRef={refDesing}/>
            <Amenidades forwardRef={refAmenidades}/>
            <Ubicacion forwardRef={refUbicacion}/>
            <Contacto forwardRef={refContacto}/>
        </WrapperSections>
      <Footer/>
      </>
    }
    </div>
  );
}

export default Home;

const WrapperSections = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 100px 8px 100px;
    height: auto;
    background-image: url(${bgSection});
    background-position: top left; 
    background-repeat: no-repeat; 
    background-size: 1000px;
    @media ${device.tablet} {
        padding: 8px 40px 8px 40px;
        background-size: 500px;
    }
`
const Preloader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${bgPreloader});
    width: 100%;
    height: 100vh;
    background-position: top center; 
    background-repeat: no-repeat; 
    background-size: cover;
    overflow: hidden;
`
const LogoPre = styled.img`
    width: 120px;
    
`
const ArrowNext = styled.img`
    width: 100px;
    margin-top: 20px;
    cursor: pointer;
    
`