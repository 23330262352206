import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import * as Constantes from '../../../utils/Constantes';

function WrapperMap(props) {
     
    let _lat = 19.338789068779434;
    let _lng = -99.2433985162412;

    let center = {
        lat: _lat,
        lng: _lng
    };
    let mapStylesDark = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f7f7f7"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "transparent"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#a3a3a3"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#b7d2f9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#968b62"
                }
            ]
        }
    ];
    let itemsMapAux = [];
    itemsMapAux.push(
        <Marker
            key="1"
            animation={props.google.maps.Animation.DROP}
            position={{ lat: _lat, lng: _lng }}
            icon={{
                url: Constantes.mapPin,
                scaledSize: new props.google.maps.Size(80, 80)
            }}
        >
        </Marker>
    );


    return (
        <Map
            key={0}
            google={props.google}
            initialCenter={center}
            center={center}
            zoom={16}
            disableDefaultUI={true}
            styles={mapStylesDark}
        >
            {itemsMapAux}
        </Map>
    );

}
export default GoogleApiWrapper({
    apiKey: (Constantes.GOOGLE_API_KEY),
    language: 'es'
})(WrapperMap);