import React from 'react'
import styled from 'styled-components'
import ameTitle from '../../../img/sections/ame-title-gold.svg'
import imgAme from '../../../img/sections/amenidades/amenidades.jpg'
import SlideAme from './SlideAme'
import {device} from '../../../utils/BreakPoints'
export default function Amenidades(props) {
    return (
        <WrapperAme ref={props.forwardRef}>
            <TitleSection src={ameTitle} height={32}/>
            <ContainerAme bgColor={'#9da1a6'} shadow={'right'}>
                <ImgAme src={imgAme}/>
                <CardAme>
                    <p>
                    Avalon ofrece amplias oportunidades de llevar un estilo de vida equilibrado y saludable, que combine ejercicio y bienestar dentro de un lujoso condominio residencial.
                    </p>
                    <ul>
                        <li>1 Alberca</li>
                        <li>2 Asoleadero</li>
                        <li>3 Sauna</li>
                        <li>4 Vapor</li>
                        <li>5 Gimnasio</li>
                        <li>6 Salón de Clases Mixtas</li>
                        <li>7 Ludoteca</li>
                        <li>8 Salón de Juegos</li>
                        <li>9 SPA y Jacuzzi</li>
                        <li>10 Snack Bar y Juice</li>
                    </ul>
                </CardAme>
            </ContainerAme>
            <SlideAme/>
        </WrapperAme>
    )
}
const WrapperAme = styled.div`
    height: auto;
`
const TitleSection = styled.img`
    margin-top: 40px;
    margin-bottom: 40px;
    height: ${props => props.height};
    @media ${device.tablet} {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 20px;
    }
    @media ${device.mobileL} {
        height: 16px;
    }
`
const ContainerAme = styled.div`
    background-color: ${props => props.bgColor};
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 80px;
    @media ${device.tablet} {
        flex-wrap: wrap;
    };
`
const ImgAme = styled.img`
    width: 55%;
    box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    -webkit-box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    -moz-box-shadow: 10px 0px 22px 1px rgba(0,0,0,0.28);
    @media ${device.tablet} {
        width: 100%;
    };
`
const CardAme = styled.div`
    width:50%;
    padding: 40px;
    color: #f2f2f2;
    @media ${device.tablet} {
        padding: 10px;
        width:100%;
    };
    p{
        font-weight: 100;
        font-size: 1.2em;
        font-family: 'Aeonik-Light';
        text-align: left;
        padding-left: 0px;
        margin-bottom: 40px;
        @media ${device.tablet} {
            font-size: 1em;
        };
    }
    li{
        list-style-type: none;
        font-size: 1.2em;
        font-family: 'Aeonik-Light';
        @media ${device.tablet} {
            font-size: 1em;
        };
    }
`