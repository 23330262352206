import React from 'react'
import { map } from "lodash";
import styled from 'styled-components'
import bgHero from '../../img/hero/hero-bg.jpg'
import logoWhite from '../../img/header/logo-white.svg'
import designTitle from '../../img/hero/design-title.svg'
import ameTitle from '../../img/hero/ame-title.svg'
import ubicacionTitle from '../../img/hero/ubicacion-title.svg'
import arrow from '../../img/hero/arrow-down.svg'
import { device } from '../../utils/BreakPoints'

export default function Hero({onSelectMenu, forwardRef}) {

    function SelectMenu(value){
        onSelectMenu(value)
    }
    
    return (
        <WrapperHero ref={forwardRef}>
            <WrapperCards>
                <ColumnCards>
                    <TitleCards>
                        <TitleHero>CONOCE</TitleHero>
                        <TitleHeroMob>CONOCE MONOLITH</TitleHeroMob>
                        <LogoHero src={logoWhite}/>
                        <SloganMob>
                            <Paragraph>Un concepto que combina lujo y naturaleza, único en la zona.</Paragraph>
                        </SloganMob>
                    </TitleCards>
                    { map(cards, (card,i) =>(
                        <Card key={i}>
                            <TitleCard src={card.titulo} height={card.height}/>
                            <Parrafo>{card.parrafo}</Parrafo>
                            <ButtonCard onClick={()=>{SelectMenu(card.nav)}}>
                                <p>{card.button}</p>
                                <Arrowdown src={arrow} width={20}/>
                            </ButtonCard>
                        </Card> 
                    ))}
                </ColumnCards>
                <Slogan>
                    <Paragraph>Un concepto que combina lujo y naturaleza, único en la zona.</Paragraph>
                </Slogan>
            </WrapperCards>
        </WrapperHero>
    )
}

const WrapperHero = styled.div`
    background-image: url(${bgHero});
    width: 100%;
    height: 2400px;
    background-position: top center; 
    background-repeat: no-repeat; 
    background-size: cover;
`
const WrapperCards = styled.div`
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    padding: 80px;
    @media ${device.tablet} {
        padding: 20px;
        width: 90%;
    }
    @media ${device.mobileL} {
        width: 90%;
    }
    
`
const ColumnCards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 0px 8px 0px;
    border-right: 3px solid #525F5E;
    width: 50%;
    @media ${device.tablet} {
        border-right: 0px solid #525F5E;
        border-left: 2px solid #525F5E;
        padding: 8px 0px 8px 20px;
        align-items: flex-start;
        width: 60%;
    }
    @media ${device.mobileL} {
        width: 100%;
    }
`
const TitleCards = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 0px 20px 8px;
    border-bottom: 1px solid #274E5F;
    @media ${device.tablet} {
        width: 50%;
        flex-direction: column;
        align-items: flex-start;
        padding: 28px 0px 0px 8px
    }
`
const TitleHero = styled.h3`
    font-size: 10px;
    font-weight: normal;
    color: white;
    letter-spacing: 1em;
    @media ${device.laptop} {
        display: none;
    }
`
const TitleHeroMob = styled.h3`
    font-size: 10px;
    font-weight: normal;
    color: white;
    letter-spacing: 1em;
    display: none;
    @media ${device.laptop} {
        display: block;
        font-size: 10px;
        letter-spacing: 1em;
        tex-align: center;
    }
    @media ${device.tablet} {
        font-size: 6px;
        letter-spacing: .5em;
        tex-align: left;
    }
    
`
const LogoHero = styled.img`
    width: 80px;
    margin-right: 16px;
    @media ${device.laptop} {
        display: none;
    }
`
const Slogan = styled.div`
    width: 50%;
    display: flex;
    align-items: flex-end;
    padding: 8px 0px 9px 0px;
    height: 100px;
    border-bottom: 1px solid #274E5F;
    @media ${device.tablet} {
        display: none;
        
    }
`
const SloganMob = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 8px 0px 9px 0px;
    height: auto;
    border-bottom: 1px solid #274E5F;
    display: none;
    @media ${device.tablet} {
        display: block; 
    }
`
const Paragraph = styled.p`
    font-size: 12px;
    font-weight: lighter;
    letter-spacing: .3em;
    color: white;
    width: 80%;
    padding: 0px 0px 0px 40px;
    @media ${device.tablet} {
        padding: 0px 0px 0px 0px;
        font-size: 8px;
    }
`
const cards = [
    {
        titulo: designTitle,
        parrafo:"La naturaleza de la ciudad se construye a partir de edificaciones que motivan su estilo.Espectacular y sofisiticado proyecto de usos mixtos compuesto por una elegante torre residencial y una torre de oficinas. El diseño arquitectónico único  fué concebido con un especial enfoque en la persona y su interacción con la naturaleza, armoniza con el entorno de cada área. Ávalon es un proyecto atemporal y tan atractivo como funcional. El estilo de vida que sustentará Ávalon mejora la existencia.",
        button:"Ver más",
        height: "28px",
        nav: 'desing'
    },

    {
        titulo: ameTitle,
        parrafo:"Las amenidades de Ávalon ofrecen oportunidades para actividades tanto recreativas como deportivas.\nAlberca\nAsoleadero\nSauna\nVapor\nGimnasio\nSalón de Clases Mixtas\nLudoteca\nSalón de Juegos\nSPA & Jacuzzi\nSnack Bar & Juice`",
        button:"Ver Amenidades",
        height: "20px",
        nav: 'amenidades'
    },
    {
        titulo: ubicacionTitle,
        parrafo: "Desierto de los Leones, es la puerta de entrada a una amplia variedad de actividades culturales y artísticas, espacios deportivos y restaurantes típicos. Próximo a importantes vías de acceso. Haciendo de este la fusión prefecta entre estética, función y ubicación. ",
        button:"Explorar el entorno",
        height: "30px",
        nav: 'ubicacion'
    }
]

const Card = styled.div`
    margin: 0;
    margin-top: 40px;
`
const TitleCard = styled.img`
    margin-bottom: 20px;
    height: ${props => props.height};
    @media ${device.tablet} {
        height: 18px;
    }
    @media ${device.mobileL} {
        height: 14px;
    }
`          
const Parrafo = styled.p`
    font-size: 14px;
    color: white;
    width: 60%;
    margin-bottom: 20px;
    @media ${device.tablet} {
        width: 100%;
    }
`
const ButtonCard = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    color: white;
    width: 50%;
    font-family: Avenir-Medium;
    cursor: pointer;
    @media ${device.tablet} {
        width: 100%;
    }
`
const Arrowdown = styled.img`
    width: ${props => props.width};
    margin-left: 20px;
`