import React, {Component} from 'react'
import { map } from "lodash";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components'
import slideImg from '../../../img/sections/amenidades/sliderAme1.jpg'
import ArrowBack from './ArrowBack';
import ArrowNext from './ArrowNext';
import {device} from '../../../utils/BreakPoints'

/* Content simulado */
const content =[
    {
        slide: "1/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img:slideImg,
    },
    {
        slide: "2/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
    {
        slide: "3/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
    {
        slide: "4/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
    {
        slide: "5/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
    {
        slide: "6/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
    {
        slide: "7/7",
        summary: "Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.",
        img: slideImg,
    },
]

export default class SlideAme extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);  
        this.state = {
            nav1: null,
            nav2: null
          };
    }
    
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        this.setState({
          nav1: this.slider,
          nav2: this.slider2
        });
      }
    
    render(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: null,
        nextArrow: null,
        arrows: false,
    };

    return (
        <ContainerSlider>
            <SlideCopys>
                    <Slider asNavFor={this.state.nav2} ref={c => (this.slider = c)} {...settings}>
                    { map(content, (slide,i) =>(
                            <Card key={i}>
                                <NumberSlide>
                                    {slide.slide}
                                </NumberSlide>
                                <Parrafo>
                                    {slide.summary}
                                </Parrafo>
                            </Card> 
                        ))}
                        
                    </Slider>
                <ContentArrow>
                    <ArrowBack width={32} onClick={this.previous} />
                    <ArrowNext width={32} onClick={this.next} />
                </ContentArrow>
            </SlideCopys>
            <SlideImg>
                <Slider asNavFor={this.state.nav1} {...settings} ref={slider => (this.slider2 = slider)}>
                { map(content, (slide,i) =>(
                        <ContainerImg key={i}>
                            <ImgSlide src={slideImg}/>
                        </ContainerImg>
                    ))}
                </Slider>
            </SlideImg>
        </ContainerSlider>
        )
    }
}
/* Slider Diseño */
const ContainerSlider = styled.div`
    display: block;
    position relative;
    width: 100%;
    height: auto;
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        height: 600px;
    }
    @media ${device.mobileL} {
        height: 540px;
    }
`
const SlideCopys = styled.div`
    width: 20%;
    height: 600px;
    background-color: rgba(5,26,28,0.7);
    color: white;
    padding: 40px;
    position: absolute;
    @media ${device.desktop} {
        height: 660px;
    }
    @media ${device.laptopL} {
        height: 620px;
    }
    @media ${device.laptopS} {
        height: 574px;
    }
    @media ${device.laptop} {
        height: 382px;
    }
    @media ${device.tablet} {
        width: 88.5%;
        height: 100px;
        left: 0;
        bottom:0%;
        background-color: rgba(5,26,28,1);
    }
    @media ${device.mobileL} {
        width: 88%;
        height: 100px;
        padding: 10px 20px 20px 20px;
    }
    @media ${device.mobileM} {
        width: 87%;
    }
    @media ${device.mobileS} {
        width: 84%;
    }
`
const SlideImg = styled.div`
    width: 100%;
    position: relative;
    top: 0;
    bottom: 0;
    z-index: -1;
    @media ${device.tablet} {
        position: relative;
        width: 100%;
        height: 420px;
    }
    
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
`
const NumberSlide =styled.h2`
    text-align: left;
    color: #ffffff;
    font-weight: 300;
    font-size: 4em;
    line-height: 2em;
    font-family: 'Avenir-Medium';
    opacity: .4;
    @media ${device.laptop} {
        font-size: 2.5em;
        line-height: 2em;
    }
    @media ${device.tablet} {
        font-size: 2em;
        text-align: center;
    }
`
const Parrafo = styled.p`
    width: 100%;
    font-family: 'Aeonik-Light';
    font-size: 1em;
    @media ${device.laptop} {
        font-size: .7em;
    }
    @media ${device.tablet} {
        font-size: .5em;
    }
    @media ${device.mobileL} {
        margin: 0 auto;
        width:86%;
        font-size: .4em;
    }
`
const ContainerImg = styled.div`
    width: 100%;
    position: relative;
`
const ImgSlide = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media ${device.tablet} {
        width: auto;
        height: 100%
    }
`
/* Custom Arrows Slider */
const ContentArrow = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-self: flex-end;
    position: absolute;
    bottom:4%;
    @media ${device.tablet} {
        width: 100%;
        bottom:25%;
        left: 1%;
    }
`
